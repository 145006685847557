interface EducationData {
  name: string;
  institution: string;
  year: string;
  gpa: string;
  description: string;
}

const data: EducationData[] = [
  {
    name: 'Bachelor of Science in Digital Media & Web Technology',
    institution: 'University of Maryland Global Campus',
    year: '2020',
    gpa: 'Summa Cum Laude, GPA 4.0',
    description:
      'Gained hands-on experience in web development, illustration, and photo manipulation',
  },
  {
    name: 'Master of Business Administration',
    institution: 'University of Maryland Global Campus',
    year: '2018',
    gpa: 'GPA 4.0',
    description:
      'Focus on finance, marketing, human resources, business law, strategy, and leadership',
  },
  {
    name: 'Bachelor of Science in Management Studies',
    institution: 'University of Maryland Global Campus',
    year: '2016',
    gpa: 'GPA 3.72',
    description:
      'Business management principles focused on ethical decision making, problem solving, and leadership',
  },
];

export default data;
