interface LinksProps {
  footer?: boolean;
}

const Links = ({ footer }: LinksProps) => {
  return (
    <div>
      <div className='bg-dark flex items-center justify-center gap-4 px-4 py-8 lg:px-8'>
        {/* LinkedIn */}
        <a
          href='https://www.linkedin.com/in/theartofwarren'
          target='_blank'
          rel='noreferrer'
          title='LinkedIn'
        >
          <svg
            className='h-8 w-8'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 291.319 291.319'
          >
            <g>
              <path
                fill='currentColor'
                d='M145.659,0c80.45,0,145.66,65.219,145.66,145.66s-65.21,145.659-145.66,145.659S0,226.1,0,145.66
              S65.21,0,145.659,0z'
              />
              <path
                className='fill-dark'
                d='M82.079,200.136h27.275v-90.91H82.079V200.136z M188.338,106.077
              c-13.237,0-25.081,4.834-33.483,15.504v-12.654H127.48v91.21h27.375v-49.324c0-10.424,9.55-20.593,21.512-20.593
              s14.912,10.169,14.912,20.338v49.57h27.275v-51.6C218.553,112.686,201.584,106.077,188.338,106.077z M95.589,100.141
              c7.538,0,13.656-6.118,13.656-13.656S103.127,72.83,95.589,72.83s-13.656,6.118-13.656,13.656S88.051,100.141,95.589,100.141z'
              />
            </g>
          </svg>
        </a>
        {/* GitHub */}
        <a
          href='https://github.com/WarrenMfg'
          target='_blank'
          rel='noreferrer'
          title='GitHub'
        >
          <svg
            className='h-8 w-8'
            viewBox='0 0 98 96'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M48.854 0C21.839 0 0 22 0 49.217c0 21.756 13.993 40.172 33.405 46.69 2.427.49 3.316-1.059 3.316-2.362 0-1.141-.08-5.052-.08-9.127-13.59 2.934-16.42-5.867-16.42-5.867-2.184-5.704-5.42-7.17-5.42-7.17-4.448-3.015.324-3.015.324-3.015 4.934.326 7.523 5.052 7.523 5.052 4.367 7.496 11.404 5.378 14.235 4.074.404-3.178 1.699-5.378 3.074-6.6-10.839-1.141-22.243-5.378-22.243-24.283 0-5.378 1.94-9.778 5.014-13.2-.485-1.222-2.184-6.275.486-13.038 0 0 4.125-1.304 13.426 5.052a46.97 46.97 0 0 1 12.214-1.63c4.125 0 8.33.571 12.213 1.63 9.302-6.356 13.427-5.052 13.427-5.052 2.67 6.763.97 11.816.485 13.038 3.155 3.422 5.015 7.822 5.015 13.2 0 18.905-11.404 23.06-22.324 24.283 1.78 1.548 3.316 4.481 3.316 9.126 0 6.6-.08 11.897-.08 13.526 0 1.304.89 2.853 3.316 2.364 19.412-6.52 33.405-24.935 33.405-46.691C97.707 22 75.788 0 48.854 0z'
              fill='currentColor'
            />
          </svg>
        </a>
      </div>

      {/* Footer */}
      {footer && (
        <p className='mb-8 text-center text-xs'>All opinions are my own</p>
      )}
    </div>
  );
};
export default Links;
