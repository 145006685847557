import { FormEvent, useState, useEffect, useCallback, useRef } from 'react';
import cn from 'classnames';

const overflowHidden = 'overflow-hidden';
const FOCUSABLE_ELEMENTS =
  'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable]';

const Contact = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [wasSubmitted, setWasSubmitted] = useState(false);
  const openModalButtonRef = useRef<HTMLButtonElement | null>(null);
  const modalRef = useRef<HTMLFormElement | null>(null);
  const tabItemsRef = useRef<HTMLElement[] | null>(null);

  // clear and close modal
  const clearAndClose = useCallback(() => {
    setMessage('');
    setEmail('');
    setIsOpen(false);
    setIsSending(false);
    setTimeout(() => openModalButtonRef.current?.focus(), 100);
  }, []);

  // tab trap for useEffect
  const trapTab = useCallback((e: KeyboardEvent) => {
    if (e.key.toLowerCase() === 'tab') {
      // shift + tab
      if (e.shiftKey) {
        if (document.activeElement === tabItemsRef.current?.[0]) {
          e.preventDefault();
          tabItemsRef.current?.[tabItemsRef.current.length - 1]?.focus();
        }
        // tab
      } else {
        if (
          document.activeElement ===
          tabItemsRef.current?.[tabItemsRef.current.length - 1]
        ) {
          e.preventDefault();
          tabItemsRef.current?.[0].focus();
        }
      }
    }
  }, []);

  // user feedback after submitting form
  useEffect(() => {
    if (wasSubmitted) {
      setTimeout(() => setWasSubmitted(false), 3000);
    }
  }, [wasSubmitted]);

  // body lock
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add(overflowHidden);
    } else {
      document.body.classList.remove(overflowHidden);
    }
  }, [isOpen]);

  // close modal when user presses the 'Escape' key
  useEffect(() => {
    const escKeyHandler = (e: KeyboardEvent) =>
      e.key.toLowerCase() === 'escape' && clearAndClose();
    window.addEventListener('keydown', escKeyHandler);

    return () => {
      window.removeEventListener('keydown', escKeyHandler);
    };
  }, [clearAndClose]);

  // add a tab trap
  useEffect(() => {
    window.addEventListener('keydown', trapTab);
    tabItemsRef.current = Array.from(
      modalRef.current?.querySelectorAll(FOCUSABLE_ELEMENTS) || []
    ) as HTMLElement[];

    return () => window.removeEventListener('keydown', trapTab);
  }, [trapTab]);

  // submit contact form
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsSending(true);

    try {
      const res = await fetch('/api/send-message', {
        method: 'POST',
        body: JSON.stringify({
          message,
          email,
        }),
      });
      if (!res.ok) throw res.statusText;
      setWasSubmitted(true);
    } catch (error) {
      console.error(error);
    }

    clearAndClose();
  };

  return (
    <>
      <button
        ref={openModalButtonRef}
        title={wasSubmitted ? 'Success!' : 'Contact'}
        className={cn('bg-blue rounded-full p-4', {
          hidden: isOpen,
          'fixed bottom-4 right-4 lg:right-1/2 lg:translate-x-[496px]': !isOpen,
        })}
        onClick={() => setIsOpen(true)}
        disabled={wasSubmitted}
      >
        {wasSubmitted ? (
          // thumbs up
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={1.5}
            stroke='currentColor'
            className='animate-pulse-sm h-10 w-10'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z'
            />
          </svg>
        ) : (
          // chat
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={1.5}
            stroke='currentColor'
            className='h-10 w-10'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155'
            />
          </svg>
        )}
      </button>

      <form
        ref={modalRef}
        role='dialog'
        aria-modal={true}
        aria-labelledby='form-title'
        onSubmit={handleSubmit}
        className={cn('text-dark overflow-y-scroll p-4 sm:p-8', {
          'bg-light fixed inset-0': isOpen,
          hidden: !isOpen,
        })}
      >
        <div className='mx-auto w-full max-w-screen-sm'>
          <h1 className='mb-4' id='form-title'>
            Let's chat!
          </h1>
          <label htmlFor='message' className='mb-4 flex h-72 flex-col'>
            <span className='mb-2 font-bold'>Message</span>
            <textarea
              name='message'
              disabled={isSending}
              placeholder='Say something'
              title='Say something'
              required
              id='message'
              className='grow resize-none rounded-lg p-4 text-lg'
              value={message}
              onChange={({ target }) => setMessage(target.value)}
            ></textarea>
          </label>
          <label htmlFor='email' className='mb-8 flex flex-col'>
            <span className='mb-2 font-bold'>Email</span>
            <input
              className='rounded-lg px-4 py-2 text-lg'
              required
              disabled={isSending}
              placeholder='you@example.com'
              title='you@example.com'
              type='email'
              name='email'
              id='email'
              value={email}
              onChange={({ target }) => setEmail(target.value)}
            />
          </label>
          <div className='mx-auto grid grid-cols-2 gap-4 sm:gap-8'>
            <button
              type='button'
              disabled={isSending}
              onClick={clearAndClose}
              className='border-dark rounded-full border py-4'
            >
              Cancel
            </button>
            <button
              type='submit'
              className='bg-dark text-light rounded-full py-4'
              disabled={isSending}
            >
              {isSending ? (
                <div className='bg-dark border-t-light border-x-dark border-b-dark border-light mx-auto h-4 w-4 animate-spin rounded-full border-2' />
              ) : (
                'Send'
              )}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
export default Contact;
