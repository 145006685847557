import { useCallback, useState } from 'react';
import cn from 'classnames';
import {
  useIsScrolledToRight,
  useIsScrolledToLeft,
} from '../../hooks/useIsScrolledTo';
import { IMedia, MediaType } from '../Experience/data';

// GitHub
const GITHUB_BASE_URL = 'https://github.com/WarrenMfg/';
// YouTube
const YOUTUBE_BASE_URL = 'https://www.youtube.com/watch?v=';
const YOUTUBE_IMG_BASE_URL = 'https://i.ytimg.com/vi/';
const YOUTUBE_IMG_PATHNAME = '/hqdefault.jpg';

interface MediaProps {
  media: IMedia[];
}

const Media = ({ media }: MediaProps) => {
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const isScrolledToRight = useIsScrolledToRight(ref);
  const isScrolledToLeft = useIsScrolledToLeft(ref);

  const handleRef = useCallback((node: HTMLDivElement) => {
    setRef(node);
  }, []);

  return (
    <div
      className={cn('relative', {
        'before:from-dark/0 before:to-dark/100 before:pointer-events-none before:absolute before:top-0 before:bottom-0 before:left-0 before:w-10 before:bg-gradient-to-l':
          !isScrolledToLeft,
        'after:from-dark/0 after:to-dark/100 after:pointer-events-none after:absolute after:top-0 after:bottom-0 after:right-0 after:w-10 after:bg-gradient-to-r':
          !isScrolledToRight,
      })}
    >
      <div
        ref={handleRef}
        className='thin-scrollbar flex w-full gap-4 overflow-scroll pb-2'
      >
        {media.map((m, i) => {
          let href = '/images/';
          let src = '/images/';
          switch (m.type) {
            case MediaType.GITHUB:
              href = `${GITHUB_BASE_URL}${m.source}`;
              src += `${m.source}.jpeg`;
              break;
            case MediaType.IMG:
              href += m.source;
              src += m.source;
              break;
            case MediaType.USGS:
              href = `https://pubs.usgs.gov/publication/${m.source}`;
              src += `${m.source}.webp`;
              break;
            case MediaType.SPOTIFY:
              href = `https://open.spotify.com/album/${m.source}`;
              src += `${m.source}.webp`;
              break;
            case MediaType.YOUTUBE:
              href = `${YOUTUBE_BASE_URL}${m.source}`;
              src = `${YOUTUBE_IMG_BASE_URL}${m.source}${YOUTUBE_IMG_PATHNAME}`;
              break;
            default:
              break;
          }
          return (
            <a
              href={href}
              target='_blank'
              rel='noreferrer'
              key={i}
              className='shrink-0 overflow-hidden rounded-lg'
            >
              <img
                src={src}
                alt={m.title}
                className='bg-medium h-20 w-32 object-cover lg:h-24 lg:w-40'
                title={m.title}
                loading='lazy'
              />
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default Media;
