interface OopsProps {
  errorMessage: string;
}

const Oops = ({ errorMessage }: OopsProps) => {
  return (
    <main className='flex flex-col items-center p-4 sm:p-8'>
      <h1 className='mb-2 text-center'>Oops!</h1>
      <h2 className='mb-4'>An error occurred.</h2>
      <p className='mb-4 inline-block rounded-md bg-red-900/30 p-2 text-center font-mono text-red-400'>
        {errorMessage}
      </p>
      <p className='mb-2'>Please try again.</p>
    </main>
  );
};

export default Oops;
