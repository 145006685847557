import { Component, ErrorInfo, PropsWithChildren } from 'react';

interface FallbackUIError {
  errorMessage: string;
}

interface ErrorBoundaryProps extends PropsWithChildren {
  fallbackUI: (props: FallbackUIError) => JSX.Element;
}

interface ErrorBoundaryState {
  error: null | Error;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      error: null,
    };
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // TODO: add telemetry
  }

  render() {
    if (this.state.error) {
      const FallbackUI = this.props.fallbackUI;
      return <FallbackUI errorMessage={this.state.error.message} />;
    } else {
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
