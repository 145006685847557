const CareerGoal = () => {
  return (
    <section className='flex flex-col items-center justify-center'>
      <h1 className='mb-4'>Career Goal</h1>
      <p className='mb-8 text-center'>
        To drive innovation <br className='sm:hidden' /> and efficiency in
        digital products, <br />
        while mentoring and empowering <br className='sm:hidden' />
        the next generation of tech professionals.
      </p>
      <h2 className='mb-2'>Book Time With Me</h2>
      <p className='mb-3 text-center'>
        Free mentorship <br className='sm:hidden' />
        for those entering the tech world, <br />
        veterans, or anyone seeking career advice
      </p>
      <a
        target='_blank'
        rel='noreferrer'
        className='bg-dark text-light rounded-full py-4 px-8 font-bold shadow-xl'
        href='https://calendly.com/the-art-of-warren/30min'
      >
        Calendly
      </a>
    </section>
  );
};
export default CareerGoal;
