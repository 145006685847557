import { useEffect, useState } from 'react';

export const useIsScrolledToRight = (ref: HTMLDivElement | null) => {
  const [isScrolledToRight, setIsScrolledToRight] = useState(true);

  useEffect(() => {
    if (!ref) return;
    else {
      // update default
      setIsScrolledToRight(
        ref.scrollWidth === ref.clientWidth ||
          ref.scrollWidth - ref.scrollLeft === ref.clientWidth
      );
    }

    const handler = () => {
      setIsScrolledToRight(
        ref.scrollWidth === ref.clientWidth ||
          ref.scrollWidth - ref.scrollLeft <= ref.clientWidth
      );
    };

    ref.addEventListener('scroll', handler);
    window.addEventListener('resize', handler);

    return () => {
      ref.removeEventListener('scroll', handler);
      window.removeEventListener('resize', handler);
    };
  }, [ref]);

  return isScrolledToRight;
};

export const useIsScrolledToLeft = (ref: HTMLDivElement | null) => {
  const [isScrolledToLeft, setIsScrolledToLeft] = useState(true);

  useEffect(() => {
    if (!ref) return;
    else {
      // update default
      setIsScrolledToLeft(ref.scrollLeft <= 0);
    }

    const handler = () => {
      setIsScrolledToLeft(ref.scrollLeft <= 0);
    };

    ref.addEventListener('scroll', handler);
    window.addEventListener('resize', handler);

    return () => {
      ref.removeEventListener('scroll', handler);
      window.removeEventListener('resize', handler);
    };
  }, [ref]);

  return isScrolledToLeft;
};
